import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Image from "react-bootstrap/Image"

const AboutUsPage = () => {

  const data = useStaticQuery(graphql`
    query StoreFrontImage {
      cloudinaryMedia(public_id: {eq: "star-caps-hero-images/front-of-store_ya64hr"}) {
        secure_url
      }
    }
  `)

  const storeImage = data.cloudinaryMedia.secure_url

  return (
  <Layout>
    <SEO title="About Us" />
    <h1 className="mb-5">ABOUT US</h1>
    <Image 
    fluid
    src={storeImage}
    alt="Store sign"
    className="mb-5"
    />
    <p className="lead">Family owned and operated, Star Caps has been serving the Houston area since 2013. Our customers range from individual and small businesses, to large organizations and corporations. All customizations are done in house using state of the art machines and presses.</p>
    <br />
    <p className="lead">At Star Caps, we are committed to providing excellent customer service to ensure your order has the highest quality possible.</p>
  </Layout>
  )
}

export default AboutUsPage

